import * as React from 'react'
import Layout from '../components/layout'

const AboutPage = () => {
    return (
        <Layout title="About page">
            About page
        </Layout>
    )
}

export default AboutPage
